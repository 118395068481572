

























































































































































































































































































































































































































































































































































































.btnBox{
  display: flex;justify-content: center;margin-top: 20px;
}
/deep/.el-form-item--small .el-form-item__error{
  padding-top: 6px;
}

/deep/ .seltAllbtnDis .cell .el-checkbox__inner {
  display: none;
  position: relative;
}
/deep/ .seltAllbtnDis .cell::before{
  content: '勾选正确答案';
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
/deep/.el-table th.el-table__cell{
  background: #ecf5ff!important;
  color: #333!important;
  border-color: #ecf5ff!important;
}
.img-el-upload{
  margin: 0 auto;
  width: 88px;
  height: 88px;

}
/deep/.el-upload{
  width: 100%;
  height: 100%;
}
/deep/.el-form-item{
  margin-bottom: 22px;
}
//.editor{
//  width: 700px!important;
//}
