.btnBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
 /deep/ .el-form-item--small .el-form-item__error {
  padding-top: 6px;
}
 /deep/ .seltAllbtnDis .cell .el-checkbox__inner {
  display: none;
  position: relative;
}
 /deep/ .seltAllbtnDis .cell::before {
  content: '勾选正确答案';
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
 /deep/ .el-table th.el-table__cell {
  background: #ecf5ff!important;
  color: #333!important;
  border-color: #ecf5ff!important;
}
.img-el-upload {
  margin: 0 auto;
  width: 88px;
  height: 88px;
}
 /deep/ .el-upload {
  width: 100%;
  height: 100%;
}
 /deep/ .el-form-item {
  margin-bottom: 22px;
}
/*新增子题目*/
.subset {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  transition: 0.3s;
}
.form-item-full /deep/ .el-form-item__label {
  width: 0!important;
}
.form-item-full /deep/ .el-form-item__content {
  margin-left: 0!important;
}
.questionBox > div {
  margin-bottom: 15px;
}
.questionBox > div:last-child {
  margin-bottom: 0;
}
.questionInner {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  transition: 0.3s;
}
.questionInner:hover {
  background: #ecf5ff;
}
.questionInnerLeft {
  position: relative;
  width: 100%;
}
.questionTitle {
  line-height: 1.5;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.questionType {
  white-space: nowrap;
  border-radius: 4px;
  margin-right: 6px;
  padding: 2px 8px;
  font-size: 14px;
  color: #2878ff;
  background: #fff;
  border: 1px solid #2878ff;
}
.questionOption {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 6px;
}
.questionOption span {
  width: 25%;
  margin-bottom: 10px;
}
.questionAnswer {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 100%;
}
.questionAnswer > span {
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}
.questionAnswer .questionAnswerS {
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(133, 206, 97, 0.3);
}
.questionAnswer .questionAnswerS:last-child {
  border: 0;
}
.questionAnalysis {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  width: 100%;
}
.questionAnalysis > span {
  width: 100%;
  border-radius: 4px;
  padding: 4px 8px;
  color: #e6a23c;
  background: #fdf6ec;
  border-color: #f5dab1;
}
.questionInnerRight {
  min-width: 228px;
  margin-left: 15px;
}
.showChildrenQuestion {
  cursor: pointer;
  color: #2878ff;
}
.childrenQuestionItem {
  margin-top: 15px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
}
.hh {
  word-break: break-all;
  word-wrap: break-word;
}
